import React from 'react';

export const PartnersBlock: React.FC = () => {
  return (
    <>
      <h3 className="mb-16">Partners &amp; Samenwerking</h3>
      <div className="grid grid-cols-6 gap-4">
        <img src="/img/logo/logo-s4c.jpg" alt="logo" className="" />
        <img src="/img/logo/logo-hhs.png" alt="logo" className="" />
        <img src="/img/logo/logo-denhaag.png" alt="logo" className="" />
        <img src="/img/logo/logo-dehaagsemunt.png" alt="logo" className="" />
        <img src="/img/logo/logo-dddaward.png" alt="logo" className="" />
        <img src="/img/logo/grondstoffenfiets.png" alt="logo" className="" />
      </div>
    </>
  );
};
