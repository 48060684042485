import React from 'react';
import { PartnersBlock } from '../components/partners/PartnersBlock';
import { Layout } from '../components/shared/Layout';

const IndexPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="text-center">
        <section
          className="py-32"
          style={{
            backgroundImage: "url('/img/branch-green-greenery-72509-1.jpg')",
          }}
        >
          <div className="container mx-auto">
            <h1>
              <img
                src="/img/logo/haagsche_schil_logo-color.png"
                alt="Haagsche Schil"
                className="block m-auto"
              />
            </h1>
            <h2 className="my-16 text-secondary">
              De nieuwe schillenboer van Den Haag
            </h2>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto max-w-xl">
            <img
              src="/img/logo/haagssche_schil_vignet.png"
              alt="logo"
              className="block m-auto h-32 mb-16"
            />
            <h3 className="mb-8">
              Haagsche Schil, de nieuwe schillenboer van Den Haag!
            </h3>
            <p>
              Haagsche Schil maakt Den Haag groener, duurzamer en meer
              circulair. We verwerken organische afvalstromen in de stad tot
              nieuwe producten. Voor bewoners, organisaties en bedrijven. Met de
              compost, het biogas en de kruiden en planten die we maken van de
              afvalstromen maken we Den Haag, en de wereld, een stuk groener.
            </p>
            <p>
              Haagsche Schil werkt lokaal, ons doel is dat er geen organisch
              afval meer de wijk uitgaat. De hoeveelheid afval die we moeten
              produceren moet omlaag, beter worden gescheiden en verwerkt. Dit
              bereiken we door een Haagsche Schil community te vormen van
              bewoners, bedrijven en organisaties die samen willen werken aan
              een meer groen Den Haag.
            </p>
            <p>Enthousiast geworden? Meld je direct aan!</p>
          </div>
        </section>

        <section>
          <div className="container mx-auto">
            <PartnersBlock />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
